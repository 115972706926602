.mantine-AppShell-main {
  padding-bottom: calc(var(--mantine-footer-height, 0px)) !important;
  padding-top: calc(var(--mantine-header-height, 0px)) !important;
  padding-right: 0px;
}

.mantine-Tabs-tabLabel {
  pointer-events: none;
}

.recharts-surface {
  overflow: initial !important;
}

/* * {
  font-family: "Cabin", sans-serif;
} */
