.barLabel {
  fill: #fff;
  text-anchor: middle;
  /* font-weight: lighter; */
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.barLabelOutside {
  fill: #555;
  text-anchor: start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
