.hs-input:focus,
.hs-input:focus-within {
  outline: none;
  border-color: #66a80f;
}

.hs-input {
  text-size-adjust: 100%;
  color-scheme: dark;
  -webkit-font-smoothing: antialiased;
  font: inherit;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  height: 2.25rem;
  -webkit-tap-highlight-color: transparent;
  line-height: calc(2.25rem - 0.125rem);
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 0.875rem;
  width: 100%;
  color: #c1c2c5;
  display: block;
  text-align: left;
  border: 0.0625rem solid transparent;
  background-color: #2c2e33;
  min-height: 2.25rem;
  padding-left: calc(2.25rem / 3);
  padding-right: calc(2.25rem / 3);
  border-radius: 0.25rem;
  margin-bottom: 2em;
}

.hs-button {
  text-size-adjust: 100%;
  color-scheme: dark;
  -webkit-font-smoothing: antialiased;
  font: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-box-flex: 0;
  flex-grow: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  box-sizing: border-box;
  height: 2.625rem;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  width: auto;
  border-radius: 0.25rem;
  font-weight: 600;
  position: relative;
  line-height: 1;
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
  border: 0.0625rem solid transparent;
  background-color: #66a80f;
  color: #fff;
  -webkit-appearance: button;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hs-button:active {
  -webkit-transform: translateY(0.0625rem);
  -moz-transform: translateY(0.0625rem);
  -ms-transform: translateY(0.0625rem);
  transform: translateY(0.0625rem);
}

.hs-button:hover {
  background-color: #5c940d;
}

#message-484c6be6-ad85-4bfc-86d2-ba9dcc147932 {
  height: 110px;
}

#label-email-484c6be6-ad85-4bfc-86d2-ba9dcc147932,
#abel-message-484c6be6-ad85-4bfc-86d2-ba9dcc147932 {
  text-size-adjust: 100%;
  color-scheme: dark;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  line-height: 1.55;
  box-sizing: border-box;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #c1c2c5;
  word-break: break-word;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
}
