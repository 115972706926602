.ganttVerticalContainer {
  /* overflow: hidden; */
  /* overflow: scroll; */
  font-size: 0;
  margin: 0;
  padding: 0;
}

.horizontalContainer {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  /* overflow: scroll; */
}

.wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  position: static;
  width: fit-content;
  /* max-height: 100%; */
  /* overflow-y: scroll;
  overflow-x: scroll; */
}
