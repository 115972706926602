.circle-group {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle {
  border-radius: 50%;
  position: absolute;
  transition: all 0.2s ease-in-out;
}

.small-circle {
  /* width: 30px;
  height: 30px; */
  /* background-color: #f39c12; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* font-size: 12px;
  font-weight: bold; */

  cursor: pointer;
}

.circle:hover {
  transform: scale(1.1);
  z-index: 5;
}

.outer {
  position: absolute;
}
